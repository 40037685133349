.card__title{padding: 8px 8px;
    padding: 8px 8px;
    border: 1px solid rgb(47 110 186);
    color: #757575;
    border-radius: 4px;}


.price-item{
    letter-spacing: 1px;
    font-weight: 700;
}

.product_image {       
    max-height: 168px;  
    width: 100%;         
    object-fit: contain;
                        
  }                       