.footer_link{

    display: flex;
    flex-direction: column;
}

.footer_link_a{

    padding: 5px 0;
    color: #797979;
    text-decoration: none;
    font-weight: 600;
}


.footer_link_a:hover{


    text-decoration:underline
  
}
.footer_h{
    margin-bottom: 16px;
    font-size: 22px;
    color: #212121;
}


footer{
    background: aliceblue;
    padding: 55px 0 70px;
}

.copyright{
    padding: 20px 12px;
    text-align: center;
    color: white;
    background: rgb(0, 106, 165);
    margin: 0px;
}