.image_info{
    width: 100%;
    border: 2px solid #464646;
}


.section_product{
    padding: 50px 0;
}

.product_h{
    font-size: 32px;
    color: #464646;
}

.product_price{
    color: #777777;
    font-weight: 700;
    font-size: 22px;
    margin-top: 6px;
}

.space{
    margin-top: 50px;
    padding: 0 20px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.addon>h3{
color: rgb(84 84 84);
}

.small{
    background-color: #337ab2;
    color: #ffffff;
    font-size: 18px;
    display: inline-block;
    padding: 6px 10px;
    border-radius: 4px;
    margin: 5px 8px;
}

.addon{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.space1{
    margin: 10px 25px;
    padding: 16px 25px;
    background: whitesmoke;
  
}
.space1>div>h3{
    color: #525252;
    margin-bottom: 6px;
}
.product_margin{
    margin-top: 4em;
}

.desc{
    padding: 12px 8px;
    font-weight: 600;
    color: #545454;
}

.spacing1{
    margin-top: 20px!important;
    margin-bottom: 20px!important;
}


.image-card  {
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    object-fit: contain;
    max-height: 306px;
  }
  
  .image-card:hover  {
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }


  .fix{
    overflow: hidden;
    width: 100%;
    max-height: 310px;
  }